@import 'styles/variables';
@import 'styles/extensions';

.how-to-use {
  padding-top: 100px;

  &.try-it-now {
    background: 
    radial-gradient(60% 60% at 100% 100%, $blue -8%, rgba(217, 217, 217, 0) 100%), $white;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: 100% 100%, 0 0;
    background-size: 50% 50%, 100%;
  }

  &__wrapper {
    @extend %wrapper;
    max-width: 1140px;
  
    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
    }

    @media (max-width: 475px) {
      padding-bottom: 0;
    }
  }

  h2 {
    padding-right: 0;
    padding-bottom: 70px;
    padding-left: 0;
    color: $black;
    font-weight: normal;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $blue;
    }
  }

  .sub-title {
    position: relative;
    display: inline-block;
    margin-bottom: 55px;
    color: $blue;
    font-weight: normal;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media (max-width: 475px) {
      margin-bottom: 25px;
      font-size: 18px;
    }

    &::after {
      position: absolute;
      top: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $sub-title-gray;
      transition: width 0.3s;
      content: '';
    }
  }

  &__items-block {
    padding-bottom: 150px;

    @media (min-width: 2560px) {
      padding-bottom: calc(140px * #{$zoom});
    }

    @media (max-width: 475px) {
      padding-bottom: 80px;
    }

    h3 {
      padding-bottom: 65px;
      color: $black;
      font-weight: normal;
      font-size: 24px;
      font-family: $secondary-font;
      line-height: 125%;
      letter-spacing: 0px;
      text-align: left;
  
      @media (min-width: 2560px) {
        padding-bottom: calc(35px * #{$zoom});
        font-size: calc(24px * #{$zoom});
      }
    }
  }

  &__item {
    @media (max-width: 475px) {
      display: flex;
      column-gap: 20px;
    }
  }

  &__item-icons {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    column-gap: 40px;

    @media (max-width: 475px) {
      flex-direction: column;
      row-gap: 30px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: -200%;
      z-index: 0;
      width: 100%;
      height: 1px;
      background: $black;
      transform: translateY( -50%);
      transition: all 0.2s ease 1s;
      content: '';
    }

    &.visible {
      &::before {        
        left: 0;
        transition: all 0.2s ease 1s;

        @media (max-width: 475px) {
          left: 50%;
          width: 1px;
          height: 100%;
        }
      }
    }

    & > div {
      position: relative;
      z-index: 0;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 100%;
      max-width: 83px;

      @media (max-width: 475px) {
        min-width: 42px;
      }
    }
  }

  &__item-text {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    color: $black;
    column-gap: 50px;

    @media (max-width: 475px) {
      flex-direction: column;
      row-gap: 30px;
      margin-top: 0;
    }

    & > div {
      flex: 1;
    }

    p {
      @media (min-width: 2560px) {
        font-size: calc(16px * #{$zoom});
      }
    }
  }

  &.try-it-out {
    .how-to-use__items-block-top-text,
    .how-to-use__items-block-bottom-text {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      color: $black;

      @media (min-width: 2560px) {
        max-width: calc(585px * 1.44);
        font-size: calc(16px * 1.44);
      }
    }

    .how-to-use__items-block-top-text {
      display: initial;
      
      a {
        color: $blue;
      }
    }

    .try-it-out__items-block-bottom-button {
      &.main-title__cta {
        height: inherit;
        margin: 50px auto 0;

        &:hover {
          border: 2px solid #00A3FF;
        }
      }
    }

    .try-it-out__items-block-bottom {
      display: flex;
      gap: 30px;
      justify-content: space-between;

      @media (max-width: 640px) {
        flex-direction: column;
        gap: 10px;
      }
    }

    .how-to-use__item {
      padding: 50px 0;
    }

    .how-to-use__item-icons > div {
      justify-content: flex-start;
    }

    .how-to-use__item-text > div {
      p {
        font-weight: normal;
        font-family: $secondary-font;

        @media (min-width: 2560px) {
          font-size: calc(16px * 1.44);
        }
      }
    }
  }
}