$zoom: 1.44;

$basic-font: 'OpenSans', Helvetica, Arial, sans-serif;
$secondary-font: 'ProximaNova', sans-serif;

$white:#FFFFFF;
$black: #000000;
$blue: #00A3FF;
$slider-gray: #414045;
$sub-title-gray: #65656A;
$background-footer: #646262;
$footer-gray: #909095;